<template>
	<div style="min-height: 100%;background-color: #000000;">
		<!-- 	<table border style="width: 122px;position: absolute;padding-bottom: 0;">
			<tr>
				<th>{{list1.thead[0]}}</th>
			</tr>
			<tr v-for="item of list1.tbody" style="background-color: #000000;">
				<td >{{item.trow[0]}}</td>
			</tr>
		</table> -->
		<table border>
			<tr>
				<th v-for="(item,i) in list1.thead" :key="i">{{item}}</th>
			</tr>
			<tr v-for="(item,b) in list1.tbody" :key="b">
				<td v-for="(item2,index) in item.trow" :key="index" :style="index>0&&item2.spec==1?{'cursor':'pointer'}:''"
					@click="clickTupu(item2)">
					<div v-if="index==0">{{item2}}</div>
					<div v-else style="display: flex;flex-direction: row;align-items: center;justify-content: center;">
						<div style="width: 40px;margin-right: 20px;">{{item2.num}}</div>
						<div v-if="item2.num">
							<img v-if="item2.alarm==-1" class="imgstyle" src="@assets/osImg/icon/jingbao-1.png" />
							<img v-else-if="item2.alarm==0" class="imgstyle" src="@assets/osImg/icon/jingbao0.gif" />
							<img v-else-if="item2.alarm==1" class="imgstyle" src="@assets/osImg/icon/jingbao1.gif" />
							<img v-else-if="item2.alarm==2" class="imgstyle" src="@assets/osImg/icon/jingbao2.gif" />
							<img v-else-if="item2.alarm==3" class="imgstyle" src="@assets/osImg/icon/jingbao3.gif" />
						</div>
					</div>
				</td>
			</tr>
		</table>
	</div>
</template>

<script>
	export default {
		name: "wendu",
		data() {
			return {
				list1: {},
				t:null
			}
		},
		methods: {
			//数据上下浮动
			floatNumber(num) {
				if (num == 0) {
					return 0;
				}
				let start = -3;
				let end = 3;
				let step = (Math.random() * (end - start) + start) / 100;
				let result = parseFloat((num + step).toFixed(2));
				return result >0 ? result : 0.01;
			},
			get() {
				clearInterval(this.t2);
				this.$get("backend-api/web/screen/v2/bearTemp").then((res) => {
					if (res.code == 1000) {
						this.list1 = res.data
						this.t2 = setInterval(() => {
							for (let i in this.list1.tbody) {
								let item = this.list1.tbody[i];
								for (let j in item.trow) {
									let item2 = item.trow[j];
									if (j > 0&&item2.alarm!=null) {
										item2.num=this.floatNumber(item2.num) 
									}
								}
							}
						}, 1000);
					} else {
						// this.$message.error(res.msg);
					}
				});
			},
			clickTupu(item) {
				if(item.spec==1){
					this.Bus.$emit("clickDanti",item)
				}
			}
		},
		mounted() {
			this.get();
			this.t = setInterval(this.get, 60000);
		},
		beforeDestroy() {
			clearInterval(this.t);
			clearInterval(this.t2);
		}
	}
</script>

<style lang="scss" scoped>
	.imgstyle{
		width: 20px;
		height: 20px;
		padding-top:10px;
	}
	table {
		display: inline-block;
		width: 100%;
		overflow: auto;

		tr {
			display: flex;

			th {
				background-color: #404040;
				flex: 1;
				min-height: 40px;
				text-align: center;
				font-size: 14px;
				font-weight: bold;
				color: rgba(255, 255, 255, 0.85);
				line-height: 40px;
				min-width: 120px;
			}

			td {
				flex: 1;
				min-height: 40px;
				text-align: center;
				font-size: 14px;
				font-weight: bold;
				color: rgba(255, 255, 255, 0.85);
				line-height: 40px;
				min-width: 120px;
			}
		}
	}
</style>
